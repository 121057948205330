
import { defineComponent, ref, provide, onMounted } from 'vue'

export default defineComponent({
  setup () {
    const dropzoneSingleRef = ref<HTMLElement>()
    const dropzoneMultipleRef = ref<HTMLElement>()
    const dropzoneValidationRef = ref<HTMLElement>()

    provide('bind[dropzoneSingleRef]', (el: HTMLElement) => {
      dropzoneSingleRef.value = el
    })

    provide('bind[dropzoneMultipleRef]', (el: HTMLElement) => {
      dropzoneMultipleRef.value = el
    })

    provide('bind[dropzoneValidationRef]', (el: HTMLElement) => {
      dropzoneValidationRef.value = el
    })

    onMounted(() => {
      const elDropzoneSingleRef: any = dropzoneSingleRef.value
      elDropzoneSingleRef.dropzone.on('success', () => {
        alert('Added file.')
      })
      elDropzoneSingleRef.dropzone.on('error', () => {
        alert('No more files please!')
      })

      const elDropzoneMultipleRef: any = dropzoneMultipleRef.value
      elDropzoneMultipleRef.dropzone.on('success', () => {
        alert('Added file.')
      })
      elDropzoneMultipleRef.dropzone.on('error', () => {
        alert('No more files please!')
      })

      const elDropzoneValidationRef: any = dropzoneValidationRef.value
      elDropzoneValidationRef.dropzone.on('success', () => {
        alert('Added file.')
      })
      elDropzoneValidationRef.dropzone.on('error', () => {
        alert('No more files please!')
      })
    })
  }
})
